import React from "react"
import Layout from './../components/shared/Layout';
import SectionArticles from './../components/section/SectionArticles';

const BarPage = () => (
  <Layout title="Bar Reviews">
      <SectionArticles section="Bar" description="Cafe Living Bar Reviews" title="Bar Reviews"/>
  </Layout>
)

export default BarPage;
